<!--
 * @Description: 属性配置
 * @Author: ChenXueLin
 * @Date: 2021-10-19 08:51:01
 * @LastEditTime: 2022-06-09 18:35:31
 * @LastEditors: LiangYiNing
-->
<template>
  <div
    class="edit-wrapper"
    v-loading="loading"
    :element-loading-background="loadingBackground"
  >
    <div class="edit-wrapper__body">
      <el-form
        ref="form"
        :model="formData"
        :rules="formRules"
        label-width="140px"
      >
        <!-- 属性配置 start -->
        <template>
          <div class="edit-title">属性配置</div>
          <div class="edit-content device-name">
            <el-row>
              <el-col :span="6">
                <el-form-item
                  label="设备名称"
                  prop="thirdClassId"
                  class="form-item--1"
                >
                  <el-input
                    v-model="thirdClassName"
                    disabled
                    placeholder="设备三级分类"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </template>
        <!-- 属性配置 end -->

        <!-- 终端属性信息 start -->
        <template>
          <div class="edit-title">终端属性信息</div>
          <div class="edit-content">
            <el-row>
              <el-col :span="6">
                <el-form-item label="电子锁" prop="islock">
                  <el-radio-group
                    v-model="formData.islock"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="门磁" prop="ishavelock">
                  <el-radio-group
                    v-model="formData.ishavelock"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="摄像头" prop="iscamera">
                  <el-radio-group
                    v-model="formData.iscamera"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="冷机" prop="cooler">
                  <el-radio-group
                    v-model="formData.cooler"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="液压" prop="hydraulic">
                  <el-radio-group
                    v-model="formData.hydraulic"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="油控" prop="oilcontrol">
                  <el-radio-group
                    v-model="formData.oilcontrol"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="空调" prop="aircondition">
                  <el-radio-group
                    v-model="formData.aircondition"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="中控锁" prop="centerlock">
                  <el-radio-group
                    v-model="formData.centerlock"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="断油断电" prop="cutoilelec">
                  <el-radio-group
                    v-model="formData.cutoilelec"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="尾板控制" prop="isman">
                  <el-radio-group
                    v-model="formData.isman"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="后备电源" prop="isstandbypower">
                  <el-radio-group
                    v-model="formData.isstandbypower"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="二氧化碳控制" prop="co2Ctrl">
                  <el-radio-group
                    v-model="formData.co2Ctrl"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="搅拌机" prop="ismixer">
                  <el-radio-group
                    v-model="formData.ismixer"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="双速度里程表" prop="dobulespeed">
                  <el-radio-group
                    v-model="formData.dobulespeed"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">码表</el-radio>
                    <el-radio :label="2">GPS</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="监听功能" prop="isMonitor">
                  <el-radio-group
                    v-model="formData.isMonitor"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="LCD" prop="lcd">
                  <el-radio-group
                    v-model="formData.lcd"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="3G视频" prop="video3g">
                  <el-radio-group
                    v-model="formData.video3g"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="头架分离" prop="isSeparation">
                  <el-radio-group
                    v-model="formData.isSeparation"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="是否挂车车头" prop="istrailer">
                  <el-radio-group
                    v-model="formData.istrailer"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="是否ADAS" prop="isAdas">
                  <el-radio-group
                    v-model="formData.isAdas"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="胎压" prop="isTyrebar">
                  <el-radio-group
                    v-model="formData.isTyrebar"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="载重" prop="extAttr2">
                  <el-radio-group
                    v-model="formData.extAttr2"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="视频录音" prop="extAttr3">
                  <el-radio-group
                    v-model="formData.extAttr3"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="对讲设备" prop="extAttr4">
                  <el-radio-group
                    v-model="formData.extAttr4"
                    :disabled="!loadSuccess"
                  >
                    <el-radio :label="1">是</el-radio>
                    <el-radio :label="0">否</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </template>
        <!-- 终端属性信息 end -->

        <!-- 其他属性配置 start -->
        <template>
          <div class="edit-title">其他属性配置</div>
          <div class="edit-content">
            <el-row>
              <el-col :span="8">
                <el-form-item
                  label="电源接入方式"
                  class="form-item--1"
                  prop="power"
                >
                  <e6-vr-select
                    v-model="formData.power"
                    :loading="initLoading"
                    :data="powerAccessModeList"
                    :disabled="!loadSuccess"
                    :props="{
                      id: 'codeValue',
                      label: 'codeName'
                    }"
                    placeholder="电源接入方式"
                    clearable
                  ></e6-vr-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="开关数量"
                  class="form-item--1"
                  prop="switchnum"
                >
                  <e6-vr-select
                    :disabled="!loadSuccess"
                    v-model="formData.switchnum"
                    :loading="initLoading"
                    :data="switchNumTypeList"
                    :props="{
                      id: 'codeValue',
                      label: 'codeName'
                    }"
                    placeholder="开关数量"
                    clearable
                  ></e6-vr-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="设备来源"
                  class="form-item--1"
                  prop="eqsourceid"
                >
                  <e6-vr-select
                    :disabled="!loadSuccess"
                    v-model="formData.eqsourceid"
                    :loading="initLoading"
                    :data="equipSourceList"
                    :props="{
                      id: 'codeValue',
                      label: 'codeName'
                    }"
                    placeholder="设备来源"
                    clearable
                  ></e6-vr-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="GPS天线位置"
                  class="form-item--1"
                  prop="antennalocation"
                >
                  <e6-vr-select
                    :disabled="!loadSuccess"
                    v-model="formData.antennalocation"
                    :loading="initLoading"
                    :data="gpsAntennaLocationList"
                    :props="{
                      id: 'codeValue',
                      label: 'codeName'
                    }"
                    placeholder="GPS天线位置"
                    clearable
                  ></e6-vr-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="主机版本"
                  class="form-item--1"
                  prop="mainversion"
                >
                  <el-input
                    :disabled="!loadSuccess"
                    placeholder="主机版本"
                    v-model="formData.mainversion"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="LCD版本"
                  class="form-item--1"
                  prop="lcdversion"
                >
                  <el-input
                    :disabled="!loadSuccess"
                    placeholder="LCD版本"
                    v-model="formData.lcdversion"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="拍照分辨率"
                  class="form-item--1"
                  prop="photoResolution"
                >
                  <e6-vr-select
                    :disabled="!loadSuccess"
                    v-model="formData.photoResolution"
                    :loading="initLoading"
                    :data="photoResolutionCodeList"
                    :props="{
                      id: 'codeValue',
                      label: 'codeName'
                    }"
                    placeholder="拍照分辨率"
                    clearable
                  ></e6-vr-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </template>
        <!-- 其他属性配置 end -->

        <!-- 线路信息 start -->
        <template>
          <div class="edit-title">线路信息</div>
          <div class="edit-content">
            <el-form-item label="门磁">
              <el-checkbox-group
                v-model="formData.mcChannelList"
                :disabled="!loadSuccess"
              >
                <el-checkbox :label="1">一路</el-checkbox>
                <el-checkbox :label="2">二路</el-checkbox>
                <el-checkbox :label="3">三路</el-checkbox>
                <el-checkbox :label="4">四路</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="继电器">
              <el-checkbox-group
                v-model="formData.relayChannelList"
                :disabled="!loadSuccess"
              >
                <el-checkbox :label="1">一路</el-checkbox>
                <el-checkbox :label="2">二路</el-checkbox>
                <el-checkbox :label="3">三路</el-checkbox>
                <el-checkbox :label="4">四路</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="湿度">
              <el-checkbox-group
                v-model="formData.humidityChannelList"
                :disabled="!loadSuccess"
              >
                <el-checkbox :label="1">一路</el-checkbox>
                <el-checkbox :label="2">二路</el-checkbox>
                <el-checkbox :label="3">三路</el-checkbox>
                <el-checkbox :label="4">四路</el-checkbox>
                <el-checkbox :label="5">五路</el-checkbox>
                <el-checkbox :label="6">六路</el-checkbox>
                <el-checkbox :label="7">七路</el-checkbox>
                <el-checkbox :label="8">八路</el-checkbox>
                <el-checkbox :label="9">九路</el-checkbox>
                <el-checkbox :label="10">十路</el-checkbox>
                <el-checkbox :label="11">十一路</el-checkbox>
                <el-checkbox :label="12">十二路</el-checkbox>
                <el-checkbox :label="13">十三路</el-checkbox>
                <el-checkbox :label="14">十四路</el-checkbox>
                <el-checkbox :label="15">十五路</el-checkbox>
                <el-checkbox :label="16">十六路</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="温控">
              <el-checkbox-group
                v-model="formData.temperaturectrlList"
                :disabled="!loadSuccess"
              >
                <el-checkbox :label="1">一路</el-checkbox>
                <el-checkbox :label="2">二路</el-checkbox>
                <el-checkbox :label="3">三路</el-checkbox>
                <el-checkbox :label="4">四路</el-checkbox>
                <el-checkbox :label="5">五路</el-checkbox>
                <el-checkbox :label="6">六路</el-checkbox>
                <el-checkbox :label="7">七路</el-checkbox>
                <el-checkbox :label="8">八路</el-checkbox>
                <el-checkbox :label="9">九路</el-checkbox>
                <el-checkbox :label="10">十路</el-checkbox>
                <el-checkbox :label="11">十一路</el-checkbox>
                <el-checkbox :label="12">十二路</el-checkbox>
                <el-checkbox :label="13">十三路</el-checkbox>
                <el-checkbox :label="14">十四路</el-checkbox>
                <el-checkbox :label="15">十五路</el-checkbox>
                <el-checkbox :label="16">十六路</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="摄像头">
              <el-checkbox-group
                v-model="formData.cameraChannelList"
                :disabled="!loadSuccess"
              >
                <el-checkbox :label="1">一路</el-checkbox>
                <el-checkbox :label="2">二路</el-checkbox>
                <el-checkbox :label="3">三路</el-checkbox>
                <el-checkbox :label="4">四路</el-checkbox>
                <el-checkbox :label="5">五路</el-checkbox>
                <el-checkbox :label="6">六路</el-checkbox>
                <el-checkbox :label="7">七路</el-checkbox>
                <el-checkbox :label="8">八路</el-checkbox>
                <el-checkbox :label="9">九路</el-checkbox>
                <el-checkbox :label="10">十路</el-checkbox>
                <el-checkbox :label="11">十一路</el-checkbox>
                <el-checkbox :label="12">十二路</el-checkbox>
                <el-checkbox :label="13">十三路</el-checkbox>
                <el-checkbox :label="14">十四路</el-checkbox>
                <el-checkbox :label="15">十五路</el-checkbox>
                <el-checkbox :label="16">十六路</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <!-- TODO: 传感器应用后续添加 -->
            <el-form-item label="传感器应用">
              <el-checkbox-group
                v-model="formData.appidList"
                :disabled="!loadSuccess"
              >
                <el-checkbox
                  :label="item.id"
                  v-for="item in sensorAppList"
                  :key="item.id"
                  >{{ item.appName }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
            <el-form-item
              label="备注"
              class="form-item--2"
              prop="remark"
              :disabled="!loadSuccess"
            >
              <el-input
                type="textarea"
                :rows="3"
                placeholder="请输入备注"
                v-model="formData.remark"
                maxlength="100"
                :disabled="!loadSuccess"
              ></el-input>
            </el-form-item>
          </div>
        </template>
        <!-- 线路信息 end -->
      </el-form>
    </div>
    <div class="edit-wrapper__footer">
      <el-button type="primary" @click="handleSaveAdd">确定</el-button>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
// import listPage from "@/mixins/list-page";
// import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import {
  updateTerminalAttr,
  getTerminalAttr,
  findDownList,
  getSensorList
} from "@/api";
export default {
  name: "",
  components: {},
  mixins: [base],
  data() {
    return {
      thirdClassName: "", // 商品三级分类
      thirdClassId: "",
      loadSuccess: false,
      formData: {
        thirdClassId: "",
        appidList: [],
        /*** 终端属性信息 ***/
        islock: 0, // 电子锁
        ishavelock: 0, // 门磁
        iscamera: 0, // 摄像头
        cooler: 0, // 冷机
        hydraulic: 0, // 液压
        oilcontrol: 0, // 油控制
        aircondition: 0, // 空调
        centerlock: 0, // 中控锁
        cutoilelec: 0, // 断油断电
        isman: 0, // 尾板控制
        isstandbypower: 0, // 后备电源
        co2Ctrl: 0, // 二氧化碳控制
        ismixer: 0, // 搅拌机
        dobulespeed: 1, // 双速度里程表
        isMonitor: 0, // 监听功能
        lcd: 0, // LCD
        video3g: 0, // 3G视频
        isSeparation: 0, // 头架分离
        istrailer: 0, // 是否挂车车头
        isAdas: 0, // 是否ADAS
        isTyrebar: 0, // 胎压
        extAttr2: 0, // 载重
        extAttr3: 0, // 视频录音
        extAttr4: 0, // 对讲设备
        /*** 其他属性配置 ***/
        power: "", // 电源接入方式
        switchnum: "", // 开关数量
        eqsourceid: "", // 设备来源
        antennalocation: "", // GPS 天线位置
        mainversion: "", // 主机版本
        lcdversion: "", // LCD版本
        photoResolution: "", // 拍照分辨率
        /*** 线路信息 ***/
        mcChannel: "", // 门磁
        mcChannelList: [],
        humidityChannel: "", // 温度
        humidityChannelList: [],
        temperaturectrl: "", // 温控
        temperaturectrlList: [],
        relayChannel: "", // 继电器
        relayChannelList: [],
        cameraChannel: "", // 摄像头
        cameraChannelList: [],
        remark: "" // 备注
      },
      formRules: {},
      sensorAppList: [], //传感器列表
      terminalHostAttribute: {}, // 便携设备
      initLoading: false, // init 数据loading状态
      // 电源接入方式
      powerAccessModeList: [],
      // 开关数量
      switchNumTypeList: [],
      // GPS天线位置
      gpsAntennaLocationList: [],
      // 拍照分辨率
      photoResolutionCodeList: [],
      // 设备来源
      equipSourceList: []
    };
  },
  computed: {},
  watch: {},
  created() {
    this.initData();
  },
  methods: {
    // 初始化
    init(terminalId, thirdClassName) {
      this.thirdClassName = thirdClassName;
      let hasParamsRoute = this.getHasParamsRoute(); // base Mixin的方法
      this.thirdClassId = hasParamsRoute.params.thirdClassId;
      this.getSensorList();
      this.getTerminalAttr(terminalId);
    },
    // 确定click
    handleSaveAdd() {
      // 处理入参
      let queryData = JSON.parse(JSON.stringify(this.formData));
      delete queryData.mcChannel;
      delete queryData.relayChannel;
      delete queryData.temperaturectrl;
      delete queryData.humidityChannel;
      delete queryData.cameraChannel;
      queryData.appid = queryData.appidList.join(",");
      this.updateTerminalAttr(queryData);
    },
    // 更新
    async updateTerminalAttr(queryData) {
      try {
        this.loading = true;
        let res = await updateTerminalAttr(queryData);
        if (res.code === "OK") {
          this.$message.success("修改成功");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取设备属性
    async getTerminalAttr(terminalId) {
      try {
        this.loading = true;
        let res = await getTerminalAttr({ id: terminalId });
        let data = this.getFreezeResponse(res, {
          path: "data"
        });
        if (res.code == "OK") {
          data = JSON.parse(JSON.stringify(data));
          data.eqsourceid += "";
          data.power += "";
          data.switchnum += "";
          data.antennalocation += "";
          data.photoResolution += "";
          data.terminalId = terminalId;
          this.formData = data;
          this.loadSuccess = true;
        }

        // data.mcChannelList = [];
        // data.humidityChannelList = [];
        // data.temperaturectrlList = [];
        // data.cameraChannelList = [];
        // data.relayChannelList = [];
      } catch (error) {
        this.loadSuccess = false;
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    async getSensorList() {
      try {
        let res = await getSensorList({
          appName: "",
          equThirdTypeId: this.thirdClassId,
          sensors: ""
        });
        if (res.code !== "OK") {
          return;
        }
        //传感器列表
        this.sensorAppList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    async initData() {
      try {
        this.initLoading = true;
        let promiseList = [
          findDownList([
            "powerAccessMode",
            "switchNumType",
            "gpsAntennaLocation",
            "photoResolution",
            "protocolTypeCode"
          ])
        ];
        let [selectRes] = await Promise.all(promiseList);
        //电源接入方式
        this.powerAccessModeList = this.getFreezeResponse(selectRes, {
          path: "data.powerAccessMode"
        });
        //开关数量
        this.switchNumTypeList = this.getFreezeResponse(selectRes, {
          path: "data.switchNumType"
        });
        //Gps天线位置
        this.gpsAntennaLocationList = this.getFreezeResponse(selectRes, {
          path: "data.gpsAntennaLocation"
        });
        //拍照分辨率
        this.photoResolutionCodeList = this.getFreezeResponse(selectRes, {
          path: "data.photoResolution"
        });
        //设备来源
        this.equipSourceList = this.getFreezeResponse(selectRes, {
          path: "data.protocolTypeCode"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.initLoading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-wrapper {
  position: relative;
  height: 100%;
}
.edit-title {
  margin-bottom: 10px;
}
</style>
